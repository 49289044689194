/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-08 12:05:42 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-09-07 18:20:34
 */

<template>
  <div class="Paper">
    <!-- <iframe
          class="filename"
          :src="fileUrl"
          width="100%"
          height="100%"
          frameborder="0"
          id="myiframe"
        ></iframe> -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      fileUrl: "",
    };
  },
  components: {
  },
  created () {

  },
  mounted () {
    this.handlePreview(this.$route.query.fileUrl)
  },
  methods: {
    handlePreview (url) {
      // let Base64 = require('js-base64').Base64;
      // this.fileUrl = this.GLOBAL.hostUrl+encodeURIComponent(Base64.encode(url));
      debugger
      let fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
      this.fileUrl = "https://admin.pulijiaoyu.org.cn/Upload/" + fileName + "/" + fileName + ".html";
      window.open(this.fileUrl);
    },
  },
};
</script>

<style lang="less" scoped>
.Paper {
  > * {
    //禁止复制
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    width: 100%;
    height: 100%;
  }
}
#myiframe {
  position: absolute;
  transform: scale(0.5, 0.5) translate(-50%, -50%);
  width: 200%;
  height: 200%;
  top: 0;
  left: 0;
}
</style>